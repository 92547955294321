<template>
  <div id="PurchaseOrderScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-9 pa-5">
          <purchase-order-list
            :getPurchaseOrdersFilter="getPurchaseOrders"
          ></purchase-order-list>
        </v-col>
        <v-col class="col-12 col-lg-3 pa-5">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="fromDate"
                      label="Dari Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    @input="menuFromDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="toDate"
                      label="Sampai Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    @input="menuToDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  dense
                  outlined
                  label="Nomor PO"
                  v-model="noPo"
                  clearable
                ></v-text-field>
                <v-autocomplete
                  dense
                  outlined
                  v-model="statusSelect"
                  :items="statusList"
                  item-text="status_name"
                  item-value="status_id"
                  label="Status"
                  clearable
                ></v-autocomplete>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn block color="primary" @click="getPurchaseOrdersData"
                >Cari</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PurchaseOrderList from "@/components/Widget/supplier/PurchaseOrderList";
import globalMixin from "@/mixins/globalMixin.js";
import PurchaseOrderService from "@/services/PurchaseOrderService";
import CommonService from "@/services/CommonService";

export default {
  components: {
    PurchaseOrderList
  },

  mixins: [globalMixin],

  data() {
    return {
      productName: "",
      fromDate: "",
      toDate: "",
      menuFromDate: false,
      menuToDate: false,
      noPo: "",
      valid: true,
      getPurchaseOrders: [],
      statusSelect: "",
      statusList: []
    };
  },

  mounted() {
    this.getAll();
    this.getStatus();
  },

  methods: {
    getStatus() {
      CommonService.getStatus()
        .then(response => {
          this.statusList = response.data.statuses;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },
    getAll() {
      PurchaseOrderService.getAll()
        .then(response => {
          this.getPurchaseOrders = response.data.purchase_orders;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },

    getPurchaseOrdersData() {
      this.$root.$loaderModal.start("Loading...");

      const payload = {
        start_date: this.fromDate,
        end_date: this.toDate,
        po_number: this.noPo,
        status_id: this.statusSelect
      };

      PurchaseOrderService.getBySearch(payload)
        .then(response => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error"
            });
          } else {
            this.getPurchaseOrders = response.data.purchase_orders;
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    }
  }
};
</script>
