<template>
  <div>
    <v-card>
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Daftar Pesanan Barang</h5>
        </v-toolbar-title>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="getPurchaseOrdersFilter"
            class="elevation-0"
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr
                :class="{ 'font-weight-bold': props.item.flag_important === 1 }"
              >
                <td class="detailLink" @click="getDetail(props.item.po_id)">
                  {{ props.item.po_number }}
                </td>
                <td>{{ props.item.store_name }}</td>
                <td>
                  {{ getDate(props.item.order_date) }}
                </td>
                <td>{{ props.item.total_item }}</td>
                <td>{{ formatAmount(props.item.total_price) }}</td>
                <td>{{ props.item.order_status }}</td>
                <td>{{ convertMinutes(props.item.deadline_date) }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="1000">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Nomor PO: {{ dataDetail.poNumber }}</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div v-if="dataDetail.flagApprove">
            <v-btn
              color="success"
              class="me-3"
              @click="approveData(dataDetail.poId)"
              :disabled="loading"
              :loading="loading"
              >Terima</v-btn
            >
            <v-btn
              color="error"
              @click="rejectData(dataDetail.poId)"
              :disabled="loading"
              >Tolak</v-btn
            >
          </div>
        </v-app-bar>

        <v-card-text class="pa-0">
          <v-container>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
              v-if="isReasonReject"
            >
              <v-row class="d-flex justify-end mr-1">
                <v-col class="col-5">
                  <v-textarea
                    v-model="dataDetail.reasonMessage"
                    outlined
                    counter
                    no-resize
                    clearable
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Nama Pemesan
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp; {{ dataDetail.orderName }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Metode Pembayaran
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp; {{ dataDetail.paymentMethod }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Alamat Pemesan
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp; {{ dataDetail.address }}
                </div>
              </v-col>
            </v-row>
            <v-row v-if="dataDetail.reason">
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Alasan Dibatalkan
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp; {{ dataDetail.reason }}
                </div>
              </v-col>
            </v-row>

            <v-card class="my-10">
              <v-app-bar dense color="transparent">
                <v-toolbar-title>
                  <h5>Daftar Produk</h5>
                </v-toolbar-title>
              </v-app-bar>

              <v-card-text class="pa-0">
                <template>
                  <v-data-table
                    :headers="headersDetail"
                    :items="dataDetail.orderDetail"
                    class="elevation-0"
                    mobile-breakpoint="0"
                  >
                    <template slot="item" slot-scope="props">
                      <tr>
                        <td>{{ props.item.product_name }}</td>
                        <td>{{ props.item.product_code }}</td>
                        <td>{{ formatAmount(props.item.quantity_order) }}</td>
                        <td>
                          {{ formatRupiah(props.item.price_order) }}
                        </td>
                        <td>
                          {{ formatRupiah(props.item.sub_total) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text-h6 text--primary text-end">
                  Total :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text-h6 text--primary text-end">
                  {{ formatRupiah(dataDetail.grandTotal) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import PurchaseOrderService from "@/services/PurchaseOrderService";

export default {
  props: ["getPurchaseOrdersFilter"],
  mixins: [globalMixin],

  data() {
    return {
      dialog: false,
      loading: false,
      isReasonReject: false,
      headers: [
        { text: "Nomor PO", sortable: false },
        { text: "Name Toko Bangunan", sortable: false },
        { text: "Tanggal Pemesanan", sortable: false },
        { text: "Jumlah Barang", sortable: false },
        { text: "Total", sortable: false },
        { text: "Status Pesanan", sortable: false },
        { text: "Jatuh Tempo Dalam", sortable: false }
      ],
      headersDetail: [
        { text: "Nama Produk", sortable: false },
        { text: "SKU", sortable: false },
        { text: "Jumlah Barang", sortable: false },
        { text: "Harga Satuan", sortable: false },
        { text: "Sub Total", sortable: false }
      ],
      methodColor: [null, "#432575", "#00AA13", "#E62B1E", "primary"], //cash, ovo, gopay, linkAja, transferBank
      dataDetail: {
        poId: "",
        flagApprove: "",
        poNumber: "",
        orderName: "",
        address: "",
        paymentMethod: "",
        orderDetail: [],
        grandTotal: "",
        reasonMessage: "",
        reason: ""
      }
    };
  },
  methods: {
    getDetail(id) {
      this.$root.$loaderModal.start("Loading...");

      const payload = {
        id: id
      };

      PurchaseOrderService.getDetail(payload)
        .then(response => {
          this.dataDetail.poId = response.data.purchase_order.po_id;
          this.dataDetail.poNumber = response.data.purchase_order.po_number;
          this.dataDetail.orderName = response.data.purchase_order.order_name;
          this.dataDetail.address = response.data.purchase_order.order_address;
          this.dataDetail.paymentMethod =
            response.data.purchase_order.payment_method;
          this.dataDetail.reason = response.data.purchase_order.reject_reason;
          this.dataDetail.grandTotal = response.data.purchase_order.grand_total;
          this.dataDetail.orderDetail = response.data.purchase_order.detail;
          this.dataDetail.flagApprove =
            response.data.purchase_order.flag_can_approve;
          this.dialog = true;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },
    approveData(id) {
      this.loading = true;

      this.$root.$loaderModal.start("Loading...");

      const payload = {
        po_id: id
      };

      PurchaseOrderService.approvePo(payload)
        .then(() => {
          this.dialog = false;

          this.$tostini({
            message: "Berhasil Diterima!",
            type: "success"
          });

          PurchaseOrderService.getAll()
            .then(response => {
              this.getPurchaseOrdersFilter = response.data.purchase_orders;
            })
            .catch(() => {
              this.$tostini({
                message: "Terjadi Kesalahan!",
                type: "error"
              });
            });

          this.$root.$emit("updateNotifSupplier");
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
          this.$root.$loaderModal.hide();
        });
    },
    rejectData(id) {
      this.loading = true;

      if (this.dataDetail.reasonMessage === "") {
        this.isReasonReject = true;
        this.loading = false;

        this.$tostini({
          message: "Alasan Wajib Diisi!",
          type: "error"
        });

        return;
      }

      this.$root.$loaderModal.start("Loading...");

      const payload = {
        po_id: id,
        reason_message: this.dataDetail.reasonMessage
      };

      PurchaseOrderService.rejectPo(payload)
        .then(() => {
          this.dialog = false;

          this.$tostini({
            message: "Berhasil Dibatalkan!",
            type: "error"
          });

          PurchaseOrderService.getAll()
            .then(response => {
              this.getPurchaseOrdersFilter = response.data.purchase_orders;
            })
            .catch(() => {
              this.$tostini({
                message: "Terjadi Kesalahan!",
                type: "error"
              });
            });

          this.$root.$emit("updateNotifSupplier");
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
          this.isReasonReject = false;
          this.$root.$loaderModal.hide();
        });
    }
  }
};
</script>
