import api from "@/services/ApiService";

export default {
  getAll() {
    return api().post("/supplier/purchase_order");
  },
  getDetail(id) {
    return api().post(`/supplier/purchase_order/detail`, id);
  },
  getBySearch(params) {
    return api().post(`/supplier/purchase_order`, params);
  },
  approvePo(params) {
    return api().post(`/supplier/purchase_order/approve`, params);
  },
  rejectPo(params) {
    return api().post(`/supplier/purchase_order/reject`, params);
  }
};
